import React, { useEffect, useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import Card from "react-bootstrap/Card"
import "nouislider/distribute/nouislider.css"
import PlotCard from "../components/PlotCard"
import { Link } from "react-router-dom"

import { ReactComponent as GridIcon } from "../icons/grid-icon.svg"
import { ReactComponent as CompareIcon } from "../icons/compare-icon.svg"

import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"

import { priceFormatter } from "../components/Helpers"

import FavButton from "../components/FavButton"

function Favourite() {
  //app state
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const [favHomes, setFavHomes] = useState([])

  const [favHomesCustomFields, setSavHomesCustomField] = useState([])
  const [faveHomeFloorData, setFaveHomeFloorData] = useState(false)

  //view state
  const [viewState, setViewState] = useState("compare")

  useEffect(() => {
    //filter out only show fav homes.
    if (appState.plotsData && appState.favPlots) {
      let favHomes = appState.plotsData.filter((el) => {
        if (appState.favPlots.includes(el.id)) {
          return el
        }
        return null
      })
      setFavHomes(favHomes)
    }
  }, [appState.favPlots, appState.plotsData])

  //get full list of custom fields
  useEffect(() => {
    let usedCustomFields = []

    favHomes.filter((el) => {
      // console.log("fav", el)
      if (el.customFields) {
        el.customFields.filter((cf) => {
          if (cf && cf.dataFilterType !== null) {
            usedCustomFields.push(cf)
            return cf
          } else {
            return null
          }
        })
      }
      return null
    })

    let customFiltersIDs = [
      ...new Set(usedCustomFields.flatMap(({ id }) => id))
    ].sort()

    let favCustomField = []

    customFiltersIDs.forEach(function (id) {
      //get this field
      let field = usedCustomFields.filter((cf) => {
        if (cf.id === id) {
          return cf
        }
        return null
      })
      favCustomField.push({
        id: field[0].id,
        displayName: field[0].displayName,
        dataFilterType: field[0].dataFilterType
      })
    })
    setSavHomesCustomField(favCustomField)
  }, [favHomes])

  //Check for floor data on one plot
  useEffect(() => {
    let faveHomeFloorData = favHomes.filter((el) => {
      if (el.floorData) {
        return el
      } else {
        return null
      }
    })

    if (faveHomeFloorData && faveHomeFloorData.length >= 1) {
      setFaveHomeFloorData(true)
    }
  }, [favHomes])

  //clearFaves
  function clearFavPlots() {
    appDispatch({ type: "clearFavPlots" })
    // console.log("test")
  }

  return (
    <Page
      title="Your Favourites"
      container={true}
      fluid={false}
      spaceBelow={true}
    >
      <h2 className="page-title">Your Favourites</h2>

      {viewState === "grid" ? (
        <div className="plots-grid" key="plots-grid">
          <div className="plots-list row">
            {favHomes.length >= 1 ? (
              favHomes.map((item) => {
                return <PlotCard key={item.id} item={item} />
              })
            ) : (
              <div className="col-md-12 plot-card item" key={0}>
                <Card className="mb-4 shadow-sm">
                  <Card.Body>
                    Looks like you don't have any favourites.
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="plots-compare" key="plots-compare">
          <div className="compare-table-wrap">
            <div className="compare-table">
              <table>
                <tbody>
                  <tr>
                    <td></td>

                    {favHomes.length >= 1 ? (
                      favHomes.map((item, index) => {
                        return (
                          <td key={`fav-card-${item.id}-${index}`}>
                            <div className="card-wrap">
                              <div className="card-title">
                                <h4 className="plot-name">
                                  <Link to={`/plots/${item.id}`}>
                                    Plot {item.plotNumber}
                                  </Link>
                                </h4>
                                <h5 className="house-name">{item.name}</h5>
                              </div>
                              <FavButton plotID={item.id} />{" "}
                            </div>
                          </td>
                        )
                      })
                    ) : (
                      <td>Looks like you don't have any favourites.</td>
                    )}
                  </tr>

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Price</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-price-${item.id}-${index}`}>
                                <div className="item-wrap price">
                                  {priceFormatter(item.price)}
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Bedrooms</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-beds-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  {item.plotType.numberOfBeds} bed
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    faveHomeFloorData ? (
                      <tr>
                        <th>Building</th>
                        {favHomes.length >= 1
                          ? favHomes.map((item, index) => {
                              return (
                                <td key={`fav-block-${item.id}-${index}`}>
                                  <div className="item-wrap">
                                    {item.floorData.blockName}
                                  </div>
                                </td>
                              )
                            })
                          : null}
                      </tr>
                    ) : null
                  ) : null}

                  {favHomes.length >= 1 ? (
                    faveHomeFloorData ? (
                      <tr>
                        <th>Floor</th>
                        {favHomes.length >= 1
                          ? favHomes.map((item, index) => {
                              return (
                                <td key={`fav-floor-${item.id}-${index}`}>
                                  <div className="item-wrap">
                                    {item.floorData
                                      ? item.floorData.floorName
                                      : "-"}
                                  </div>
                                </td>
                              )
                            })
                          : null}
                      </tr>
                    ) : null
                  ) : null}

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Area</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-area-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  {item.plotType.imperialArea} sq ft
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomesCustomFields.length >= 1
                    ? favHomesCustomFields.map((favField, index) => {
                        return (
                          <tr key={`fav-field${index}`}>
                            <th>{favField.displayName}</th>
                            {favHomes.length >= 1
                              ? favHomes.map((item, index) => {
                                  let result = null
                                  if (item.customFields) {
                                    result = item.customFields.find((cf) => {
                                      return cf.id === favField.id
                                    })
                                  }

                                  let val = null
                                  if (result) {
                                    if (
                                      result.dataType === 5 &&
                                      result.fieldValue === "true"
                                    ) {
                                      val = "Yes"
                                    } else {
                                      val = result.fieldValue
                                    }
                                  }

                                  return (
                                    <td key={`fav-cf-${favField.id}-${index}`}>
                                      <div className="item-wrap">
                                        {val ? val : "-"}
                                      </div>
                                    </td>
                                  )
                                })
                              : null}
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6">
          {/* <LoginForm /> */}

          {favHomes.length >= 1 ? (
            <button onClick={clearFavPlots} className="btn btn-secondary">
              <span className="button-text">Clear All Favourites</span>
            </button>
          ) : null}
        </div>
      </div>

      <div className="sub-menu centered">
        <div className="container">
          <ButtonGroup aria-label="View" className="view-menu">
            <Button
              active={viewState === "grid" ? true : false}
              variant="secondary"
              onClick={() => setViewState("grid")}
              className="uppercase box-shadow"
            >
              <div className="svg-icon-inline baseline">
                <GridIcon />
              </div>{" "}
              Grid
            </Button>
            <Button
              active={viewState === "compare" ? true : false}
              variant="secondary"
              onClick={() => setViewState("compare")}
              className="uppercase box-shadow"
            >
              <div className="svg-icon-inline baseline">
                <CompareIcon />
              </div>{" "}
              Compare
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Page>
  )
}

export default Favourite
