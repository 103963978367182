import React, { useEffect, useState, useContext } from "react"
import GoogleMapReact from "google-map-react"
import Axios from "axios"
import StateContext from "../StateContext"
import Loading from "../components/Loading"
import MapMark from "../components/MapMark"
import MapMarkDevelopment from "../components/MapMarkDevelopment"
import MapFilter from "../components/MapFilter"

// const google = window.google;

function LocalArea() {
  const appState = useContext(StateContext)

  let [key, setKey] = useState(0)
  const [localAreaData, setLocalAreaData] = useState()
  const [filteredPlaces, setFilteredPlaces] = useState([])

  let devName = appState.devName

  useEffect(() => {
    document.title = `Local Area | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  //get the location data from api
  useEffect(() => {
    if (!localStorage.getItem("localArea")) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetLocalArea/${appState.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": appState.key }
            }
          )
          if (response.data) {
            //set state
            setLocalAreaData(response.data)
            setFilteredPlaces(response.data.poIs)
            setKey(key + 1) //update key to cause re-render of map

            //set localStorage
            let json = JSON.stringify(response.data)
            localStorage.setItem("localArea", json)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      //we have local data

      //set state
      const data = JSON.parse(localStorage.getItem("localArea"))
      setLocalAreaData(data)
      setFilteredPlaces(data.poIs)
    }
  }, [appState.devID, appState.key])

  //set up Google Maps

  const mapSettings = {
    center: {
      lat: 51.8860942,
      lng: 0.8336077
    },
    zoom: 5
  }

  function markerClicked(marker) {
    // console.log("The marker that was clicked is", marker)
  }

  function mapUpdated() {
    //to close the tooltip on map move
    document.body.click()
  }

  // Return map bounds based on list of places
  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds()

    filteredPlaces.forEach((place) => {
      // if(place.categoryId == 1445){
      bounds.extend(new maps.LatLng(place.latitude, place.longitude))
      // }
    })
    return bounds
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds)
      })
    })
  }

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps)

    // Fit map to bounds
    map.fitBounds(bounds)

    // Bind the resize listener
    bindResizeListener(map, maps, bounds)

    // fake scroll
    // so on iphone the tooltip follows pin
    window.scrollTo(window.scrollX, window.scrollY - 1)
    window.scrollTo(window.scrollX, window.scrollY + 1)
  }

  function createMapOptions(maps) {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      clickableIcons: false,
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      styles: [
        {
          stylers: [
            { saturation: -100 },
            { gamma: 0.8 },
            { lightness: 4 },
            { visibility: "on" }
          ]
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off"
            }
          ]
        }
      ]
    }
  }

  //filter markers on id
  function filterMarkers(id) {
    let filtered = []

    if (id === "all") {
      filtered = localAreaData.poIs
    } else {
      localAreaData.poIs.filter((el) => {
        if (
          el.categoryId === id ||
          el.categoryId === getDevelopmentCategoryID()
        ) {
          filtered.push(el)
        }
        return null
      })
    }

    setFilteredPlaces(filtered)

    //TODO add fit to bounds on filter
  }

  //look up the category color from the category list
  function getCategoryColor(id) {
    let currentCategory = localAreaData.localAreaCategories.find(
      (el) => el.id === id
    )

    let { color } = currentCategory
    return color
  }

  //get the "Development" category ID so we can display differently
  function getDevelopmentCategoryID() {
    let developmentCategory = localAreaData.localAreaCategories.find(
      (el) => el.name === "Development"
    )

    let { id } = developmentCategory
    return id
  }

  return (
    <>
      <div
        className="map-wrap"
        style={{ height: "calc(var(--vh, 1vh) * 100 - 60px)", width: "100vw" }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB8XKQG2wreOLoUjyXhlmPdwKyAFsFaNUc" }}
          defaultCenter={mapSettings.center}
          defaultZoom={mapSettings.zoom}
          onChange={mapUpdated}
          onChildClick={markerClicked}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          options={createMapOptions}
          key={key}
        >
          {filteredPlaces ? (
            filteredPlaces.map((place) => {
              if (getDevelopmentCategoryID() !== place.categoryId) {
                return (
                  <MapMark
                    key={place.id}
                    lat={place.latitude}
                    lng={place.longitude}
                    color={getCategoryColor(place.categoryId)}
                    name={place.name}
                  />
                )
              }
              return null
            })
          ) : (
            <Loading />
          )}

          {filteredPlaces
            ? filteredPlaces.map((place) => {
                if (getDevelopmentCategoryID() === place.categoryId) {
                  return (
                    <MapMarkDevelopment
                      key={place.id}
                      lat={place.latitude}
                      lng={place.longitude}
                      color={getCategoryColor(place.categoryId)}
                      name={place.name}
                    />
                  )
                }
                return null
              })
            : null}
        </GoogleMapReact>
      </div>

      <div className="map-menu">
        <div className="container">
          <div className="map-filter-wrap">
            <div className="map-filter">
              <MapFilter
                localAreaData={localAreaData}
                filterMarkers={filterMarkers}
                getDevelopmentCategoryID={getDevelopmentCategoryID}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LocalArea
