import React, { useEffect, useContext } from "react"
import Iframe from "../components/Iframe"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"

function VirtualTour() {
  const appState = useContext(StateContext)
  const id = useParams().id
  const mainTour = appState.devMenuItems.virtualTour
  let devName = appState.devName

  useEffect(() => {
    document.title = `Exterior Tour | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <Iframe src={!id ? mainTour : `${mainTour}?startscene=pano${id}`}></Iframe>
  )
}

export default VirtualTour
