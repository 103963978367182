import React, { useContext } from "react"
import StateContext from "../StateContext"
import Page from "../components/Page"

function BookAppointment() {
  const appState = useContext(StateContext)
  const BookAppointment = appState.devMenuItems.bookAppointment

  function popupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left
    var dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top

    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width
    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height

    var left = width / 2 - w / 2 + dualScreenLeft
    var top = height / 2 - h / 2 + dualScreenTop
    var newWindow = window.open(
      url,
      title,
      "scrollbars=yes, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    )

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus()
    }
  }

  const openBookAppointment = () => {
    popupCenter(BookAppointment, "Book Appointment", "900", "500")
  }

  return (
    <Page title="Book Appointment" container={true}>
      <div className="center-content-wrap">
        <div className="content text-center">
          <h2 className="page-title">
            Book Your Appointment at {appState.devName}
          </h2>
          <button onClick={openBookAppointment} className="btn btn-secondary">
            Open Redrow Website
          </button>
        </div>
      </div>
    </Page>
  )
}

export default BookAppointment
