import React, { useContext } from "react"
import StateContext from "../StateContext"
import { Link } from "react-router-dom"

import { ReactComponent as HeartIcon } from "../icons/heart-light.svg"

function FavStatusBar(props) {
  const appState = useContext(StateContext)
  const { isDesktopOrLaptop } = props

  return (
    <>
      {appState.favPlots && appState.favPlots.length > 1 ? (
        <Link
          className={`btn round uppercase btn-secondary box-shadow ml-2`}
          to="favourites"
        >
          <div className="button-text-link">
            <span className="button-text refine-menu-button">
              {isDesktopOrLaptop ? (
                <div
                  className="svg-icon-inline baseline"
                  style={{ pointerEvents: "none" }}
                >
                  <HeartIcon />
                </div>
              ) : null}
              Compare
            </span>
          </div>
        </Link>
      ) : null}
    </>
  )
}

export default FavStatusBar
