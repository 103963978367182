import React, { useContext, useEffect } from "react"
import Iframe from "../components/Iframe"
import StateContext from "../StateContext"
import RefineMenu from "../components/RefineMenu"
import { useMediaQuery } from "react-responsive"



function Siteplan() {
  const appState = useContext(StateContext)
  const siteplan = appState.devMenuItems.siteplan
  let devName = appState.devName

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      // setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )

  useEffect(() => {
    document.title = `Siteplan | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <>
      {/* for testing */}
      {/* <Iframe src="iframe.html"></Iframe> */}
      <Iframe src={siteplan}></Iframe>

      <div className="sub-menu centered">
        <div className="container">
          <RefineMenu isDesktopOrLaptop={isDesktopOrLaptop} />
        </div>
      </div>
    </>
  )
}

export default Siteplan
