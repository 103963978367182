import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"
import Form from "react-bootstrap/Form"
import Loading from "../components/Loading"
import { priceFormatter } from "../components/Helpers"

import { ReactComponent as RefineIcon } from "../icons/refine-icon.svg"
import { ReactComponent as CloseIcon } from "../icons/times-light.svg"

function RefineMenu(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const stateRef = useRef()
  stateRef.current = appState.filters

  // const { filters } = props
  // const { setFilters } = props
  const { isDesktopOrLaptop } = props

  // console.log(filters)

  //refine menu state
  const [isRefineMenuOpen, setIsRefineMenuOpen] = useState(false)

  const toggleRefineMenu = useCallback(() => {
    setIsRefineMenuOpen(!isRefineMenuOpen)
  }, [isRefineMenuOpen])

  const closeRefineMenu = useCallback(() => {
    setIsRefineMenuOpen(false)
  }, [])

  const openRefineMenu = useCallback(() => {
    setIsRefineMenuOpen(true)
  }, [])

  //checkbox

  const handleCheckboxChange = (filterIndex, valueIndex) => (e) => {
    let newFilters = JSON.parse(JSON.stringify(appState.filters)) // copying the old

    newFilters[filterIndex].values[valueIndex].isChecked =
      !newFilters[filterIndex].values[valueIndex].isChecked

    //add active state to filter
    if (newFilters[filterIndex].values.some((e) => e.isChecked)) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }

    // setFilters(newFilters)
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  //range slider

  //using sep state for the labels
  const [rangeLabels, setRangeLabels] = useState([])

  const rangeOnSlide = (filterID) => (render, handle, value, un, percent) => {
    let newRangeLabels = [...appState.rangeLabels] // copying the old
    newRangeLabels[filterID] = value
    // setRangeLabels(newRangeLabels)
    appDispatch({
      type: "setRangeLabels",
      data: newRangeLabels
    })
  }

  const rangeOnSet = (filterIndex) => (render, handle, value, un, percent) => {
    let newFilters = JSON.parse(JSON.stringify(stateRef.current))

    newFilters[filterIndex].selectedValues = value

    //add active state to filter
    if (
      value[0] !== newFilters[filterIndex].values[0] ||
      value[1] !== newFilters[filterIndex].values[1]
    ) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }

    // setFilters(newFilters)
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  //update the range labels
  // useEffect(() => {
  //   let newRangeLabels = [...rangeLabels] // copying the old
  //   newRangeLabels[1] = [minPrice, maxPrice]
  //   setRangeLabels(newRangeLabels)
  // }, [filters])

  /**
   * Hook that alerts clicks outside of the passed ref
   */

  //!event.target.classList.contains("refine-menu-button")

  function useOutsideAlerter(wrapperRef, buttonRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        //if not inside the container
        if (
          wrapperRef.current &&
          buttonRef.current &&
          !wrapperRef.current.contains(event.target) &&
          !buttonRef.current.contains(event.target)
        ) {
          //if the menu is open and the click even is not on the button
          if (isRefineMenuOpen) {
            closeRefineMenu()
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [wrapperRef, buttonRef, isRefineMenuOpen])
  }

  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)
  useOutsideAlerter(wrapperRef, buttonRef)

  let Icon = function () {
    return isRefineMenuOpen ? <CloseIcon /> : <RefineIcon />
  }

  return (
    <>
      {appState.filters && appState.filters.length ? (
        <div className="refine-menu">
          <div
            className={`btn round uppercase btn-secondary box-shadow refine-menu-button ${
              isRefineMenuOpen ? "active" : ""
            }${
              appState.filters && appState.filters.some((e) => e.active)
                ? " filtered"
                : ""
            }`}
            onClick={toggleRefineMenu}
            ref={buttonRef}
          >
            <div className="button-text-link">
              <span className="button-text ">
                {isDesktopOrLaptop ? (
                  <div
                    className="svg-icon-inline baseline"
                    style={{ pointerEvents: "none" }}
                  >
                    <Icon />
                  </div>
                ) : null}
                Refine
              </span>
            </div>
          </div>

          <div
            className={
              "off-screen-refine-menu " +
              (isRefineMenuOpen ? "off-screen-refine-menu--is-visible" : "")
            }
            ref={wrapperRef}
          >
            <Accordion defaultActiveKey={1}>
              {appState.filters
                ? appState.filters.map((item, filterIndex) => {
                    if (item.type === "range") {
                      return (
                        <Card key={filterIndex}>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={item.id}
                            className={item.active ? "filtered" : null}
                          >
                            <h5 className="uppercase">{item.displayName} </h5>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={item.id}>
                            <div className="collapse-wrap">
                              <Card.Body>
                                {Number.isInteger(item.values[0]) ? (
                                  <>
                                    <Nouislider
                                      range={{
                                        min: item.values[0],
                                        max: item.values[1]
                                      }}
                                      start={item.selectedValues}
                                      step={1000}
                                      onSet={rangeOnSet(filterIndex)}
                                      onSlide={rangeOnSlide(item.id)}
                                      connect
                                      disabled={false}
                                    />
                                    <div className="price-range pb-2">
                                      {priceFormatter(
                                        appState.rangeLabels[item.id][0]
                                      )}
                                      {" - "}
                                      {priceFormatter(
                                        appState.rangeLabels[item.id][1]
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <Loading />
                                )}
                              </Card.Body>
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      )
                    } else if (item.type === "checkbox") {
                      return (
                        <Card key={filterIndex}>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={item.id}
                            className={item.active ? "filtered" : null}
                          >
                            <h5 className="uppercase">{item.displayName}</h5>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={item.id}>
                            <div className="collapse-wrap">
                              <Card.Body>
                                <Form>
                                  {item.values.map((item, valueIndex) => {
                                    return (
                                      <Form.Check
                                        key={valueIndex}
                                        label={item.label}
                                        checked={item.isChecked}
                                        value={item.value}
                                        onChange={handleCheckboxChange(
                                          filterIndex,
                                          valueIndex
                                        )}
                                        type="checkbox"
                                        id={`check-${filterIndex}-${valueIndex}`}
                                      />
                                    )
                                  })}
                                </Form>
                              </Card.Body>
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      )
                    } else {
                      return null
                    }
                  })
                : null}
            </Accordion>
          </div>
        </div>
      ) : null}{" "}
    </>
  )
}

export default RefineMenu
