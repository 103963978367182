import React from "react"
import { Switch, Route } from "react-router-dom"

//Pages
import Home from "../pages/Home"
import Gallery from "../pages/Gallery"
import Videos from "../pages/Videos"
import Types from "../pages/Types"
// import SingleType from "./SingleType"
// import VirtualTour from "./VirtualTour"
import InteriorTour from "../pages/InteriorTour"
import ExteriorTour from "../pages/ExteriorTour"

import Plots from "../pages/Plots"
import AllPlots from "../pages/AllPlots"
import Favourites from "../pages/Favourites"
import SinglePlot from "../pages/SinglePlot"
import LocalArea from "../pages/LocalArea"
import Siteplan from "../pages/Siteplan"
import Brochure from "../pages/Brochure"
import Specification from "../pages/Specification"
import MasterPlan from "../pages/MasterPlan"
import Downloads from "../pages/Downloads"
import BookAppointment from "../pages/BookAppointment"
import AppSettings from "../pages/AppSettings"
import ComparePlots from "../pages/ComparePlots"

import NotFound from "../pages/NotFound"

function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/gallery">
        <Gallery />
      </Route>
      <Route path="/videos">
        <Videos />
      </Route>
      <Route path="/types" exact>
        <Types />
      </Route>
      {/* <Route path="/types/:id">
        <SingleType />
      </Route> */}
      <Route path="/interior-tour" exact>
        <InteriorTour />
      </Route>
      <Route path="/interior-tour/:id">
        <InteriorTour />
      </Route>
      <Route path="/exterior-tour" exact>
        <ExteriorTour />
      </Route>
      <Route path="/exterior-tour/:id">
        <ExteriorTour />
      </Route>
      {/* <Route path="/virtual-tour" exact>
        <VirtualTour />
      </Route>
      <Route path="/virtual-tour/:id">
        <VirtualTour />
      </Route> */}
      <Route path="/local-area">
        <LocalArea />
      </Route>
      <Route path="/plots/" exact>
        <Plots />
      </Route>
      <Route path="/all-plots/" exact>
        <AllPlots />
      </Route>
      <Route path="/plots/:id">
        <SinglePlot />
      </Route>
      <Route path="/siteplan">
        <Siteplan />
      </Route>
      <Route path="/favourites">
        <Favourites />
      </Route>
      <Route path="/specification" exact>
        <Specification />
      </Route>
      <Route path="/brochure" exact>
        <Brochure />
      </Route>
      <Route path="/brochure/:id">
        <Brochure />
      </Route>
      <Route path="/masterplan" exact>
        <MasterPlan />
      </Route>
      <Route path="/masterplan/:id">
        <MasterPlan />
      </Route>
      <Route path="/downloads">
        <Downloads />
      </Route>
      <Route path="/book-appointment">
        <BookAppointment />
      </Route>
      <Route path="/app-settings/:key">
        <AppSettings />
      </Route>
      <Route path="/compare" exact>
        <ComparePlots />
        </Route>
      <Route path="/compare/:id">
        <ComparePlots />
        </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Routes
