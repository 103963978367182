import React, { useState, useRef, useContext, useEffect } from "react"
import { useHistory, Link } from "react-router-dom"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { ReactComponent as CloseIcon } from "../icons/times-light.svg"

import RefineMenuHome from "../components/RefineMenuHome"

import Badge from "react-bootstrap/Badge"
function HomePopover(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const { isOpen, setIsOpen } = props

  const [filterIsActive, setFilterIsActive] = useState(false)

  //check for route change and close popover
  // const location = useLocation()
  // React.useEffect(() => {
  //   appDispatch({ type: "hidePopover" })
  //   console.log("location change")
  // }, [location])

  const history = useHistory()
  const goToRoute = (location) => history.push(location)

  //add body class to lock scroll when popover is open
  // React.useEffect(() => {
  //   if (isOpen === true) {
  //     disableBodyScroll(document.querySelector(".off-screen-popover-content"))
  //     // console.log("true")
  //   } else {
  //     enableBodyScroll(document.querySelector(".off-screen-popover-content"))
  //     // clearAllBodyScrollLocks()
  //     // console.log("false")
  //   }
  // }, [isOpen])

  function closePopover() {
    //   appDispatch({ type: "hidePopover" })
    setIsOpen(false)

    setTimeout(function () {
      setFilterIsActive(false)
    }, 200)
  }

  function visitLink(location) {
    // appDispatch({ type: "hidePopover" })
    setIsOpen(false)
    setTimeout(function () {
      setFilterIsActive(false)
    }, 200)
    // enableBodyScroll(document.querySelector(".off-screen-popover-content"))
    goToRoute(location)
  }

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (isOpen) {
            closePopover()
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, isOpen])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div
      className={
        "off-screen-popover homepage " +
        (isOpen ? "off-screen-popover--is-visible" : "")
      }
    >
      <div className="off-screen-popover-content homepage" ref={wrapperRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="home-popover-filter ">
              <div className="content">
                <h2 className="uppercase">What are you looking for?</h2>
                <RefineMenuHome isDesktopOrLaptop={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="close-button-wrap">
        <div className="close-button" onClick={closePopover}>
          <CloseIcon />
        </div>
      </div>
    </div>
  )
}

export default HomePopover
