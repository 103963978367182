import React, { useEffect, useContext } from "react"
import Iframe from "../components/Iframe"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"
import ItemMenu from "../components/ItemMenu"

function VirtualTour() {
  const appState = useContext(StateContext)
  const id = useParams().id
  let devName = appState.devName

  const typeTours = appState.devMenuItems.typeTours
  const currentTypeTour = id ? typeTours.find((el) => el.slug === id) : null

  useEffect(() => {
    document.title = `Interior Tour | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <>
      <Iframe src={id ? currentTypeTour.url : typeTours[0].url}></Iframe>

      <div className="sub-menu centered">
        <div className="container">
          {typeTours ? (
            <ItemMenu
              items={typeTours}
              currentItem={id ? currentTypeTour.slug : typeTours[0].slug}
              route="interior-tour"
              label="Interior Tours"
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default VirtualTour
