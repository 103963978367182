import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { useImmerReducer } from "use-immer"
import StateContext from "./StateContext"
import DispatchContext from "./DispatchContext"
import Axios from "axios"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { CookiesProvider } from "react-cookie"
import { useCookies } from "react-cookie"
import LightServer from "./components/LightServer"

//helpers
import {
  stringToSlug,
  fromPrice,
  toPrice,
  getStatusID
} from "./components/Helpers"

//styles
import "./styles/index.scss"

//routes
import Routes from "./components/Routes"

//components
import Loading from "./components/Loading"
import Header from "./components/Header"
import Menu from "./components/Menu"
import BackButton from "./components/BackButton"

Axios.defaults.baseURL = "https://www.think-hub.co.uk/api/v1"

// to calc the viewport size and set custom css prop
window.onresize = function () {
  var vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", "".concat(vh, "px"))
}
window.onresize()

function Main() {
  //set up initial app state
  const initialState = {
    devID: window["runConfig"].devID,
    key: window["runConfig"].devKEY,

    fetchedTypesData: Boolean(localStorage.getItem("typesData")),
    fetchedPlotsData: false, //always load plot data on refresh
    fetchedDevName: Boolean(localStorage.getItem("devName")),
    fetchedDevStyle: Boolean(localStorage.getItem("devStyle")),
    fetchedPlotStatuses: Boolean(localStorage.getItem("plotStatuses")),
    fetchedPlotContainers: Boolean(localStorage.getItem("plotContainers")),
    fetchedPlotVRs: Boolean(localStorage.getItem("plotVRs")),
    fetchedDevMenuItems: Boolean(localStorage.getItem("devMenuItems")),

    devStyle: JSON.parse(localStorage.getItem("devStyle")),
    devName: localStorage.getItem("devName"),
    typesData: JSON.parse(localStorage.getItem("typesData")),
    plotStatuses: JSON.parse(localStorage.getItem("plotStatuses")),
    plotsData: JSON.parse(localStorage.getItem("plotsData")),
    plotContainers: JSON.parse(localStorage.getItem("plotContainers")),
    plotVRs: JSON.parse(localStorage.getItem("plotVRs")),
    devMenuItems: JSON.parse(localStorage.getItem("devMenuItems")),

    loggedIn: Boolean(localStorage.getItem("user")),
    user: JSON.parse(localStorage.getItem("user")),

    favPlots: JSON.parse(localStorage.getItem("favPlots")),
    favPlotsToRemove: [],
    favPlotsToAdd: [],
    favRemoteUpdate: 0,

    isPopoverOpen: false,
    popoverContent: false,

    filters: [],
    availableHomes: [],
    filteredHomes: [],
    rangeLabels: [],

    currentLight: { ID: null, status: "off" }
  }

  function ourReducer(draft, action) {
    switch (action.type) {
      case "fetchedTypesData":
        draft.fetchedTypesData = Boolean(localStorage.getItem("typesData"))
        draft.typesData = JSON.parse(localStorage.getItem("typesData"))
        return
      case "fetchedPlotsData":
        draft.fetchedPlotsData = Boolean(localStorage.getItem("plotsData"))
        draft.plotsData = JSON.parse(localStorage.getItem("plotsData"))
        return
      case "fetchedDevName":
        draft.fetchedDevName = Boolean(localStorage.getItem("devName"))
        draft.devName = localStorage.getItem("devName")
        return
      case "fetchedDevMenuItems":
        draft.fetchedDevMenuItems = Boolean(
          localStorage.getItem("devMenuItems")
        )
        draft.devMenuItems = JSON.parse(localStorage.getItem("devMenuItems"))
        return
      case "fetchedDevStyle":
        draft.fetchedDevStyle = Boolean(localStorage.getItem("devStyle"))
        draft.devStyle = JSON.parse(localStorage.getItem("devStyle"))
        return
      case "fetchedPlotVRs":
        draft.fetchedPlotVRs = Boolean(localStorage.getItem("plotVRs"))
        draft.plotVRs = JSON.parse(localStorage.getItem("plotVRs"))
        return
      case "fetchedPlotContainers":
        draft.fetchedPlotContainers = Boolean(
          localStorage.getItem("plotContainers")
        )
        draft.plotContainers = JSON.parse(
          localStorage.getItem("plotContainers")
        )
        return

      case "fetchedPlotStatuses":
        draft.fetchedPlotStatuses = Boolean(
          localStorage.getItem("plotStatuses")
        )
        draft.plotStatuses = JSON.parse(localStorage.getItem("plotStatuses"))
        return
      case "login":
        draft.loggedIn = true
        draft.user = action.data
        return
      case "logout":
        draft.loggedIn = false
        return

      // add single plot to favs and update to remote
      case "addFavPlot":
        let plotToAdd = action.data
        if (draft.favPlots) {
          draft.favPlots = [...new Set([...draft.favPlots, plotToAdd])]
        } else {
          draft.favPlots = [plotToAdd]
        }

        //add to que to add via api
        if (draft.favPlotsToAdd) {
          draft.favPlotsToAdd = [
            ...new Set([...draft.favPlotsToAdd, plotToAdd])
          ]
        } else {
          draft.favPlotsToAdd = [plotToAdd]
        }

        draft.favRemoteUpdate++
        return

      // remove single plot from favs and remove from remote
      case "removeFavPlot":
        let plotToRemove = action.data
        if (draft.favPlots) {
          //filter out plot
          draft.favPlots = draft.favPlots.filter((plot) => {
            if (plot !== plotToRemove) {
              return plot
            }
            return null
          })

          //add to que to remove via api
          if (draft.favPlotsToRemove) {
            draft.favPlotsToRemove = [
              ...new Set([...draft.favPlotsToRemove, plotToRemove])
            ]
          } else {
            draft.favPlotsToRemove = [plotToRemove]
          }
          draft.favRemoteUpdate++
        }
        // removeRemoteFavPlots([plotToRemove])
        return

      // remove single plot from favs and remove from remote
      case "clearFavPlots":
        draft.favPlotsToRemove = draft.favPlots
        draft.favPlots = []
        draft.favRemoteUpdate++
        return

      // pull array of fav plots from remote and add to state
      case "updateLocalFavPlots":
        // draft.favPlots = [...draft.favPlots, action.data]
        draft.favPlots = action.data
        return

      //push array of fav plots to current user on remote
      case "updateRemoteFavPlots":
        // addRemoteFavPlots(action.data)
        draft.favPlotsToAdd = draft.favPlots
        draft.favRemoteUpdate++
        return

      //push array of fav plots to current user on remote
      case "syncFavPlots":
        if (draft.favPlots) {
          let newFavs = [...new Set([...draft.favPlots, ...action.data])]
          draft.favPlots = newFavs
          draft.favPlotsToAdd = newFavs
          draft.favRemoteUpdate++
        } else {
          draft.favPlots = action.data
        }
        return

      case "remotePlotRemoved":
        draft.favPlotsToRemove = []
        return

      //push array of fav plots to current user on remote
      case "remotePlotAdded":
        // addRemoteFavPlots(action.data)
        draft.favPlotsToAdd = []
        return

      //open popover
      case "showPopover":
        draft.isPopoverOpen = true
        if (action.data) {
          draft.popoverContent = action.data
        }

        return

      //close popover
      case "hidePopover":
        draft.isPopoverOpen = false
        return

      //set filters
      case "setFilters":
        draft.filters = action.data
        return

      //set available homes
      case "setAvailableHomes":
        draft.availableHomes = action.data
        return

      //set filtered homes
      case "setFilteredHomes":
        draft.filteredHomes = action.data
        return

      //set Range Labels
      case "setRangeLabels":
        draft.rangeLabels = action.data
        return

      //set current light
      case "setCurrentLight":
        draft.currentLight = action.data
        return

      //set current light
      case "turnOffCurrentLight":
        draft.currentLight.status = "off"
        return

      default:
        return
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  //handle local storage for login details
  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem("user", JSON.stringify(state.user))
    } else {
      localStorage.removeItem("user")
    }
  }, [state.loggedIn, state.user])

  // Check if logged in valid on first render
  useEffect(() => {
    if (state.loggedIn) {
      async function checkForRemoteChanges() {
        try {
          const response = await Axios.get(
            `/GetCustomerFavourites/${state.user.email}`,
            {}
          )
          dispatch({
            type: "syncFavPlots",
            data: response.data.customerFavoritePlotIds
          })
        } catch (error) {
          console.log("There was a problem.")
        }
      }
      checkForRemoteChanges()
    }
  })

  //remote remove plot fav
  useEffect(() => {
    async function removeRemoteFavPlots() {
      try {
        await Axios.delete("/ClearPlotsFromCustomerFavourites/", {
          headers: { "Content-Type": "application/json" },
          data: {
            customerEmail: state.user.email,
            PlotIds: state.favPlotsToRemove
          }
        })
        // console.log("Remote removed plot", response.data)
        //empty que
        dispatch({ type: "remotePlotRemoved", data: state.favPlotsToRemove })
      } catch (error) {
        console.log("There was a problem.")
      }
    }

    if (state.loggedIn && state.favPlotsToRemove.length > 0) {
      removeRemoteFavPlots()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.favRemoteUpdate])

  //remote add plot fav
  useEffect(() => {
    async function addRemoteFavPlots() {
      try {
        await Axios.post("/AddPlotsToCustomerFavourites/", {
          customerEmail: state.user.email,
          PlotIds: state.favPlotsToAdd
        })
        // console.log("Remote added plot", response.data)
        //empty que
        dispatch({ type: "remotePlotAdded", data: state.favPlotsToAdd })
      } catch (error) {
        console.log("There was a problem.")
        console.log(error)
        console.log(error.response)
      }
    }

    if (state.loggedIn && state.favPlotsToAdd.length > 0) {
      console.log("to add", state.favPlotsToAdd)
      addRemoteFavPlots()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.favRemoteUpdate])

  // get the plots info from api and add to local store
  useEffect(() => {
    // if (!state.fetchedPlotsData && state.plotStatuses) {
    const ourRequest = Axios.CancelToken.source()

    async function fetchResults() {
      try {
        const response = await Axios.get(`GetPlots/${state.devID}/true`, {
          cancelToken: ourRequest.token,
          headers: { "Registration-Key": state.key }
        })
        if (response.data) {
          //add floor info to plot from plot containers if exists
          let plots = null
          if (state.plotContainers) {
            //loop each plot
            plots = response.data.map((currentPlot) => {
              //get the plot containers
              let data = state.plotContainers[0]

              let sites = ""
              if (data.plotContainerType.name !== "Site") {
                sites = state.plotContainers[0].plotContainers
              } else {
                sites = state.plotContainers
              }

              let matchedFloor = false

              sites.forEach(function (site) {
                let blocks = site.plotContainers

                blocks.forEach(function (block) {
                  let floors = block.plotContainers

                  floors.forEach(function (floor) {
                    if (floor.plotIds.includes(currentPlot.id)) {
                      let plots = floor.interactiveRegions
                      plots.forEach(function (plot) {
                        if (plot.targetId === currentPlot.id) {
                          // Found a matching floor
                          matchedFloor = {
                            blockName: block.name,
                            floorName: floor.name,
                            imgURL: floor.interactiveAssetURI,
                            x: plot.startX,
                            y: plot.startY
                          }
                        }
                      })
                    }
                  })
                })
              })
              //return the matched floor
              return { ...currentPlot, floorData: matchedFloor }
            })
          } else {
            plots = response.data
          }

          //save plot data
          localStorage.setItem("plotsData", JSON.stringify(plots))
          dispatch({ type: "fetchedPlotsData" })

          //sort into types and nest plots
          let types = response.data.map((type) => {
            const typeContainer = {}
            typeContainer["id"] = type.plotType.id
            typeContainer["assets"] = type.plotType.assets
            typeContainer["description"] = type.plotType.description
            typeContainer["dimensions"] = type.plotType.dimensions
            typeContainer["disclaimerText"] = type.plotType.disclaimerText
            typeContainer["imperialArea"] = type.plotType.imperialArea
            typeContainer["metricArea"] = type.plotType.metricArea
            typeContainer["name"] = type.plotType.name
            typeContainer["slug"] = stringToSlug(type.plotType.name)
            typeContainer["numberOfBeds"] = type.plotType.numberOfBeds
            typeContainer["printPdfURI"] = type.plotType.printPdfURI

            //get list of prices min and max
            typeContainer["fromPrice"] = fromPrice(
              response.data
                .filter((plot) => {
                  if (
                    plot.plotTypeId === type.plotTypeId &&
                    plot.plotStatusId ===
                      getStatusID(state.plotStatuses, "Available")
                  ) {
                    return true
                  }

                  return false // skip
                })
                .map((plot) => {
                  let prices = []
                  prices.push(plot.price)

                  return prices
                })
            )

            typeContainer["toPrice"] = toPrice(
              response.data
                .filter((plot) => {
                  if (
                    plot.plotTypeId === type.plotTypeId &&
                    plot.plotStatusId ===
                      getStatusID(state.plotStatuses, "Available")
                  ) {
                    return true
                  }
                  return false // skip
                })
                .map((plot) => {
                  let prices = []
                  prices.push(plot.price)

                  return prices
                })
            )

            typeContainer["plots"] = response.data
              .filter((plot) => {
                if (plot.plotTypeId === type.plotTypeId) {
                  return true
                }
                return false // skip
              })
              .map((plot) => {
                const plotContainer = { ...plot }
                // plotContainer["id"] = plot.id
                // plotContainer["plotNumber"] = plot.plotNumber
                // plotContainer["plotTypeId"] = plot.plotTypeId
                // plotContainer["name"] = plot.name
                // plotContainer["plotStatusId"] = plot.plotStatusId
                // plotContainer["price"] = plot.price
                // plotContainer["description"] = plot.description

                return plotContainer
              })

            return typeContainer
          })

          //remove duplicates by ID
          const filteredTypes = types.reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id)

            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])

          localStorage.setItem("typesData", JSON.stringify(filteredTypes))
          dispatch({ type: "fetchedTypesData" })
        }
      } catch (e) {
        console.log(
          "There was a problem or the request was cancelled. (GetPlots)",
          e
        )
      }
    }
    fetchResults()
    return () => ourRequest.cancel()
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.plotStatuses, state.plotContainers])

  //get the dev data
  useEffect(() => {
    if (!state.fetchedDevName) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetStartupInformation/${state.devID}`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": state.key }
            }
          )
          if (response.data) {
            // console.log("Fetched", response.data)
            let devName = response.data.name
            localStorage.setItem("devName", devName)
            dispatch({ type: "fetchedDevName" })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetStartupInformation)"
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //get the plot container (blocks)
  useEffect(() => {
    if (!state.fetchedPlotContainers) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetPlotsContainer/${state.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": state.key }
            }
          )
          if (response.data) {
            // console.log("Fetched", response.data)
            localStorage.setItem(
              "plotContainers",
              JSON.stringify(response.data)
            )
            dispatch({ type: "fetchedPlotContainers" })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetPlotsContainer)"
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //get availability key
  useEffect(() => {
    if (!state.fetchedPlotStatuses) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetPlotStatuses/${state.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": state.key }
            }
          )
          if (response.data) {
            // console.log("Fetched", response.data)
            localStorage.setItem("plotStatuses", JSON.stringify(response.data))

            dispatch({
              type: "fetchedPlotStatuses"
            })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetPlotStatuses)"
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //get the dev style
  useEffect(() => {
    if (!state.fetchedDevStyle) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(`GetStyle/${state.devID}/true`, {
            cancelToken: ourRequest.token,
            headers: { "Registration-Key": state.key }
          })
          if (response.data) {
            // console.log("Fetched", response.data)
            localStorage.setItem("devStyle", JSON.stringify(response.data))
            dispatch({ type: "fetchedDevStyle" })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetStyle)"
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //get the menus
  useEffect(() => {
    if (!state.fetchedDevMenuItems) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(`GetMenuItems/${state.devID}/`, {
            cancelToken: ourRequest.token,
            headers: { "Registration-Key": state.key }
          })
          if (response.data) {
            let menuItems = {}

            function getMenuItem(data, name, nodeType) {
              let url = false
              let item = data.find(
                (el) => el.name === name && el.nodeType === nodeType
              )
              if (item && nodeType === "Asset") {
                url = item.assets[0].fileUrl
              } else if (item && nodeType === "Web Asset") {
                url = item.webAssetUri
              }
              return url
            }

            // console.log("fetch", response.data)

            menuItems.brochure = getMenuItem(response.data, "Brochure", "Asset")
            menuItems.specification = getMenuItem(
              response.data,
              "Specification",
              "Asset"
            )
            menuItems.siteplan = getMenuItem(response.data, "360", "Web Asset")
            menuItems.virtualTour = getMenuItem(
              response.data,
              "Site VR Tour",
              "Web Asset"
            )

            //plot vrs
            menuItems.plotVRs = getMenuItem(
              response.data,
              "Plots2VRs",
              "Web Asset"
            )
            // populate vrs with null as we don't have the location
            if (menuItems.plotVRs === false) {
              localStorage.setItem("plotVRs", null)
              dispatch({ type: "fetchedPlotVRs" })
            }

            //master plan
            let masterPlan = getMenuItem(response.data, "Masterplan", "Asset")
            menuItems.masterPlan =
              masterPlan && masterPlan.endsWith("jpg") ? masterPlan : null

            //book appointment
            menuItems.bookAppointment = getMenuItem(
              response.data,
              "Appointments",
              "Web Asset"
            )

            function getMenuSubItems(data, name, nodeType) {
              let items = []
              let group = data.find(
                (el) =>
                  el.name === name && el.nodeType === "Menu Item Container"
              )
              if (group && group.subNodes) {
                for (let i = 0; i < group.subNodes.length; ++i) {
                  let name = group.subNodes[i].name
                  let slug = stringToSlug(name)

                  let url = false
                  if (nodeType === "Asset") {
                    url = group.subNodes[i].assets[0].fileUrl
                  } else if (nodeType === "Web Asset") {
                    url = group.subNodes[i].webAssetUri
                  }

                  // items[slug] = { slug: slug, url: url, title: name }
                  items.push({ slug: slug, url: url, title: name })
                }
              } else {
                return null
              }
              return items
            }

            //get type tours
            menuItems.typeTours = getMenuSubItems(
              response.data,
              "Interior VR Tours",
              "Web Asset"
            )

            //get downloads
            menuItems.downloads = getMenuSubItems(
              response.data,
              "Downloads",
              "Asset"
            )

            //NEW MENU ITEMS

            //get 3DSiteplans
            menuItems.siteplans = getMenuSubItems(
              response.data,
              "3DSiteplans",
              "Web Asset"
            )

            //get PDFs
            menuItems.brochures = getMenuSubItems(
              response.data,
              "PDFs",
              "Asset"
            )

            //get masterplans
            menuItems.masterPlans = getMenuSubItems(
              response.data,
              "Masterplans",
              "Asset"
            )

            localStorage.setItem("devMenuItems", JSON.stringify(menuItems))
            dispatch({ type: "fetchedDevMenuItems" })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetMenuItems)",
            e
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //get plots to vrs
  //https://vr-storage-thinkbdw.s3.eu-west-2.amazonaws.com/Redrow/plotVRs.json
  useEffect(() => {
    if (
      !state.fetchedPlotVRs &&
      state.devMenuItems &&
      state.devMenuItems.plotVRs
    ) {
      var CancelToken = Axios.CancelToken

      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.request({
            url: state.devMenuItems.plotVRs,
            baseURL: "",
            cancelToken: new CancelToken(function (cancel) {})
          })
          if (response.data) {
            // console.log("Fetched", response.data)
            localStorage.setItem("plotVRs", JSON.stringify(response.data))
            dispatch({ type: "fetchedPlotVRs" })
          }
        } catch (e) {
          console.log(
            "There was a problem or the request was cancelled.(GetPlotVRs)"
          )
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  })

  //handle plot favs change
  useEffect(() => {
    localStorage.setItem("favPlots", JSON.stringify(state.favPlots))
  }, [state.favPlots])

  //clear local storage if time has passed
  useEffect(() => {
    var lastClear = localStorage.getItem("lastClear"),
      time_now = new Date().getTime()

    // .getTime() returns milliseconds so 1000 * 60 * 60 = 24 hours
    if (time_now - lastClear > 1000 * 60 * 60) {
      localStorage.clear()
      localStorage.setItem("lastClear", time_now)
    }
  }, [])

  //Set up filters
  //set available homes and set up filters
  useEffect(() => {
    let prices = []
    let bedrooms = []
    let houseTypes = []
    let floors = []

    let allCustomFilters = []

    let filtersList = []

    // loop all the plots
    if (state.plotsData && state.plotStatuses) {
      let homes = state.plotsData.filter((el) => {
        //filter out hidden
        if (el.plotStatusId !== getStatusID(state.plotStatuses, "Hidden")) {
          //only show available homes.
          if (
            el.plotStatusId === getStatusID(state.plotStatuses, "Available")
          ) {
            //get a list of all prices
            prices.push(el.price)

            //get list of all bedrooms
            bedrooms.push(el.plotType.numberOfBeds)

            //get a list of all types
            houseTypes.push(el.plotType.name)

            //get a list of all floors
            if (el.floorData) {
              floors.push(el.floorData.floorName)
            }

            //get all custom filters
            if (el.customFields) {
              el.customFields.filter((cf) => {
                if (cf && cf.dataFilterType !== null) {
                  allCustomFilters.push(cf)
                  return cf
                } else {
                  return null
                }
              })
            }

            //return the plot to the home variable
            return el
          }
        }
        return null
      })

      //get price info
      let maxPrice = toPrice(prices)
      let minPrice = fromPrice(prices)

      //get unique bedrooms
      let uniqueBedrooms = [...new Set(bedrooms)] //get unique
      let orderedBedrooms = uniqueBedrooms.sort((a, b) => a - b)
      // console.log("orderedBedrooms", orderedBedrooms)

      //get unique floors
      let uniqueFloors = [...new Set(floors)]
      let orderedFloors = uniqueFloors.sort((a, b) => a - b)
      // console.log("orderedFloors", orderedFloors)

      //house types
      let uniqueHouseTypes = [...new Set(houseTypes)]
      let orderedHouseTypes = uniqueHouseTypes.sort((a, b) => a - b)
      // console.log("orderedHouseTypes", orderedHouseTypes)

      // add to filters data
      // price
      if (!isFinite(minPrice) && !isFinite(maxPrice)) {
        // don't add the filter
      } else if (minPrice === maxPrice) {
        // don't add the filter
      } else {
        filtersList.push({
          id: 1,
          name: "price",
          displayName: "Price Range",
          type: "range",
          values: [minPrice, maxPrice],
          selectedValues: [minPrice, maxPrice]
        })

        let newRangeLabels = [...state.rangeLabels] // copying the old
        newRangeLabels[1] = [minPrice, maxPrice]

        dispatch({
          type: "setRangeLabels",
          data: newRangeLabels
        })
      }

      // bedrooms
      if (orderedBedrooms.length > 1) {
        let bedroomsValues = []
        orderedBedrooms.forEach(function (value) {
          let bedLabel = value === 1 ? `Studio/${value} bed` : `${value} beds`
          bedroomsValues.push({
            label: bedLabel,
            value: value,
            isChecked: false
          })
        })
        filtersList.push({
          id: 2,
          name: "bedrooms",
          displayName: "Bedrooms",
          type: "checkbox",
          values: bedroomsValues
        })
      }

      //floors
      if (orderedFloors.length > 1) {
        let floorValues = []
        orderedFloors.forEach(function (value) {
          floorValues.push({
            label: value,
            value: value,
            isChecked: false
          })
        })
        filtersList.push({
          id: 3,
          name: "floor",
          displayName: "Floor",
          type: "checkbox",
          values: floorValues
        })
      }

      //house types
      if (orderedHouseTypes.length > 1) {
        let houseTypesValues = []
        orderedHouseTypes.forEach(function (value) {
          houseTypesValues.push({
            label: value,
            value: value,
            isChecked: false
          })
        })

        filtersList.push({
          id: 4,
          name: "houseTypes",
          displayName: "Type",
          type: "checkbox",
          values: houseTypesValues
        })
      }

      // custom fields
      let customFiltersIDs = [
        ...new Set(allCustomFilters.flatMap(({ id }) => id))
      ].sort()

      customFiltersIDs.forEach(function (id) {
        //get this field
        let field = allCustomFilters.filter((cf) => {
          if (cf.id === id) {
            return cf
          } else {
            return null
          }
        })

        //get unique values
        let uniqueValues = [
          ...new Set(field.flatMap(({ fieldValue }) => fieldValue))
        ].sort()

        //if a checkbox
        if (field[0].dataFilterType === 2) {
          let values = []
          uniqueValues.forEach(function (value) {
            values.push({
              label: value,
              value: value,
              isChecked: false
            })
          })

          filtersList.push({
            id: id,
            name: field[0].displayName,
            displayName: field[0].displayName,
            type: "checkbox",
            values: values
          })

          //boolean
        } else if (field[0].dataFilterType === 3) {
          let values = []
          uniqueValues.forEach(function (value) {
            console.log(value)
            if (value === "true" || value === "True") {
              values.push({
                label: "Yes",
                value: value,
                isChecked: false
              })
            }
          })

          filtersList.push({
            id: id,
            name: field[0].displayName,
            displayName: field[0].displayName,
            type: "checkbox",
            values: values
          })
        } else {
          // nothing
        }
      })

      // setFilters(filtersList)
      dispatch({
        type: "setFilters",
        data: filtersList
      })

      // set available homes
      // setAvailableHomes(homes)
      dispatch({
        type: "setAvailableHomes",
        data: homes
      })

      //set all to filtered items for first render
      // setFilteredItems(homes)
      dispatch({
        type: "setFilteredItems",
        data: homes
      })
    }
  }, [state.plotStatuses, state.plotsData])

  // Filter
  //
  //
  //

  useEffect(() => {
    // console.log("appState.filters", appState.filters)
    // console.log("appState.availableHomes", appState.availableHomes)
    if (state.availableHomes) {
      var filteredResults = state.availableHomes.filter((el, index) => {
        let active = true // default to show

        if (state.filters) {
          state.filters.forEach(function (filter) {
            if (filter.type === "range") {
              //range
              let itemValue = parseInt(el[filter.name])

              if (
                filter.selectedValues[0] <= itemValue &&
                filter.selectedValues[1] >= itemValue
              ) {
              } else {
                active = false
              }
            } else if (filter.type === "checkbox") {
              //checkbox

              //get the filter values from filter into an array
              let filterValues = []
              filter.values.forEach(function (val) {
                if (val.isChecked) {
                  filterValues.push(val.value)
                }
              })

              if (filter.name === "bedrooms") {
                if (filterValues && filterValues.length) {
                  if (!filterValues.includes(el.plotType.numberOfBeds)) {
                    active = false
                  }
                }
              } else if (filter.name === "floor") {
                if (filterValues && filterValues.length) {
                  if (!filterValues.includes(el.floorData.floorName)) {
                    active = false
                  }
                }
              } else if (filter.name === "houseTypes") {
                if (filterValues && filterValues.length) {
                  if (!filterValues.includes(el.plotType.name)) {
                    active = false
                  }
                }
              } else {
                //custom filters

                if (filterValues && filterValues.length) {
                  //get this custom field value
                  let customFieldValue = null
                  if (el.customFields && el.customFields.length) {
                    el.customFields.forEach(function (customField) {
                      if (customField.id === filter.id) {
                        customFieldValue = customField.fieldValue
                      }
                    })
                  }

                  if (filterValues && filterValues.length) {
                    if (!filterValues.includes(customFieldValue)) {
                      // found element
                      active = false
                    }
                  }
                }
              }
            }
          })
        }
        return active
      })

      // setFilteredItems(filteredResults)
      dispatch({
        type: "setFilteredHomes",
        data: filteredResults
      })
    }
  }, [state.availableHomes, state.filters])

  const fetched = [
    state.fetchedPlotsData,
    state.fetchedTypesData,
    state.fetchedDevName,
    state.fetchedPlotStatuses,
    state.fetchedDevStyle,
    state.fetchedDevMenuItems,
    state.fetchedPlotContainers,
    state.fetchedPlotVRs
  ]

  const [cookies] = useCookies(["haveLights", "lightServerAddress"])

  if (fetched.includes(false)) {
    return (
      <div className="site-loader">
        <Loading />
      </div>
    )
  } else {
    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <HelmetProvider>
            <Helmet>
              {state.devStyle ? (
                <style type="text/css">{`
            :root {
              --theme-primary-color: ${state.devStyle.container.secondary};
              --theme-text-color: ${state.devStyle.container.text};
              --theme-stroke-color: ${state.devStyle.container.stroke.color};
              --theme-bg-color: ${state.devStyle.container.primary.color};
              --primary: var(--theme-primary-color);
              --secondary: var(--theme-stroke-color);
              --navVisibility: visible;
            }
          `}</style>
              ) : (
                ""
              )}
            </Helmet>
            <BrowserRouter>
              <CookiesProvider>
                {cookies.haveLights && cookies.lightServerAddress ? (
                  <LightServer
                    lightServerAddress={cookies.lightServerAddress}
                  />
                ) : null}
                <Header />
                <Menu />
                <BackButton />
                <Routes />
              </CookiesProvider>
            </BrowserRouter>
          </HelmetProvider>
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }
}

ReactDOM.render(
  // <React.StrictMode>
  <Main />,
  // </React.StrictMode>,
  document.getElementById("root")
)
