import React from "react"
import CompareList from "../pages/CompareList"
import { useParams } from "react-router-dom"

function ComparePlots(props) {

  const {id} = useParams();

  return (
    <div className="compare-container">
      <div className="compare-container__column"> <CompareList incomingPlot={parseInt(id)}/> </div>
      <div className="compare-container__column"><CompareList  /></div>
     </div>
  )
}

export default ComparePlots
