import React from "react"
import { ReactComponent as PlayIcon } from "../icons/play-circle-duotone.svg"

function VideoThumbnail(props)  {
    const {videoData, myfunction, imagesArray, captionsArray, videoPostersArray} = props;

    return(
        <div className="masonry-with-columns">
        {videoData
          ? videoData.map((item, index) => {
              captionsArray.push(item.name)
              imagesArray.push(item.link);
              if (item.link?.endsWith("mp4") || item.link?.includes('player.vimeo.com')) {

                videoPostersArray.push(
                  "https://generative-placeholders.glitch.me/image?width=600&height=300&img=20201029121356"
                )
             
                return (
                  <div
                    key={index}
                    className="video-thumb"
                    onClick={() => myfunction(index + 1)}
                  >
                    <div className="video-thumb__playIcon">
                        <PlayIcon />
                        <VideoThumbnail />
                    </div>
                   {/* <p className="video-thumb__caption">{item.name}</p> */}
                    <video className="video-thumb__preview" preload='metadata' poster={item.fileUrl} >
                        <source src={item.link} type='video/mp4' />
                    </video>
                  </div>
                )
              } else {
                return null
              }
            })
          : null
        }
      </div>
    )
}

export default VideoThumbnail

