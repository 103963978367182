import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import RefineMenu from "../components/RefineMenu"
import FilterStatusBar from "../components/FilterStatusBar"
import FavStatusBar from "../components/FavStatusBar"
import { useMediaQuery } from "react-responsive"
import CompareListItem from "../components/CompareListItem"
import SinglePlot from "../pages/SinglePlot"
import { ReactComponent as CloseIcon } from "../icons/times-light.svg"
import { sortBy } from "../components/Helpers"

function CompareList(props) {
 
  const { incomingPlot } = props
  const appState = useContext(StateContext)

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      // setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )
  
  const [viewState, setViewState] = useState();
  const [plotID, setPlotID] = useState();

  const sortedData = [...appState.filteredHomes].sort((a, b) => sortBy(a, b, 'plotNumber'));

  const showPlot = (thisId)=>{
    setPlotID(thisId);
    setViewState("compare");
  }

  useEffect(()=>{
    if(incomingPlot === undefined || isNaN(incomingPlot) === true)
    return;

    showPlot(incomingPlot);

  },[incomingPlot])
  
  const lockScroll = ()=> {
    document.querySelectorAll('.compare-container__column').forEach(function(item) {
      item.style.overflowY = "unset";
    })
  };
  
  const unlockScroll = ()=> {
    document.querySelectorAll('.compare-container__column').forEach(function(item) {
      item.style.overflowY = "auto";
    })
  };
  

  return (
  <div>
    {viewState === "compare" ? lockScroll() : unlockScroll()}
      {viewState === "compare" ? ( 
        <div>
            <div className="close-plot-button" onClick={()=>setViewState('')}>
                <CloseIcon />
            </div>
            <SinglePlot key={plotID} compareID={plotID} />
        </div>
      ) : (
        <div>
            <div className="top-bar row">
                <div className="col-md-12">
                    <FilterStatusBar />
                </div>
            </div>
            <div className="plots-list">
                <div className="plots-grid row" key="plots-grid">
                    {sortedData.length >= 1 ? (
                        sortedData.map((item) => {
                            return <CompareListItem item={item} key={item.id} myFunction={showPlot} />
                        })
                    ) : (
                    <div className="col-md-12 plot-card item" key={0}>
                        <Card className="mb-4">
                            <Card.Body>No available plots.</Card.Body>
                        </Card>
                    </div>
                    )}
                </div>
            </div>
        <div className="sub-menu centered">
            <div className="container">
                <RefineMenu isDesktopOrLaptop={isDesktopOrLaptop} />
                <FavStatusBar isDesktopOrLaptop={isDesktopOrLaptop} />
            </div>
        </div>
        {appState.filters && appState.filters.some((e) => e.active) ? null : (
        <div className="view-all-homes">
          <Link className="view-all-homes-link" to="all-plots">
            View all homes
          </Link>
        </div>
        )}
        </div>
      )}
    </div>
  )

}

export default CompareList
