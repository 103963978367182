import React, { useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"

function MasterPlan() {
  const appState = useContext(StateContext)
  const downloads = appState.devMenuItems.downloads

  return (
    <Page title="Downloads" fluid={false} container={true}>
      <h2 className="page-title">Downloads</h2>

      <div className="row">
        {downloads.length >= 1
          ? downloads.map((item) => {
              return (
                <div className="col-md-6 col-lg-4 item" key={item.slug}>
                  <Card className="mb-4">
                    <Card.Header>
                      <strong className="mr-2">{item.title}</strong>
                    </Card.Header>
                    <Card.Body>
                      <a
                        href={item.url}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        Download File
                      </a>
                    </Card.Body>
                  </Card>
                </div>
              )
            })
          : null}
      </div>
    </Page>
  )
}

export default MasterPlan
