import React, { useState, useEffect, useContext } from "react"
import Page from "../components/Page"
import Axios from "axios"
import StateContext from "../StateContext"
import Loading from "../components/Loading"
import FsLightbox from "fslightbox-react"
import VideoThumbnail from "../components/VideoThumbnail"

import { ReactComponent as PlayIcon } from "../icons/play-icon.svg"

function Gallery() {
  //will get the data for the 1st gallery in galleries in the backend
  const appState = useContext(StateContext)

  const [galleryData, setGalleryData] = useState()

  let root = document.documentElement;
  root.style.setProperty("--navVisibility", "visible");

  //get the location data from api
  useEffect(() => {
    if (!localStorage.getItem("galleryData")) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetGalleries/${appState.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": appState.key }
            }
          )
          if (response.data) {
            //set state
            setGalleryData(response.data[0].assets)

            //set localStorage
            let json = JSON.stringify(response.data)
            localStorage.setItem("galleryData", json)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      //we have local data

      //set state
      const data = JSON.parse(localStorage.getItem("galleryData"))
      setGalleryData(data[0].assets)
    }
  }, [appState.devID, appState.key])

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  const imagesArray = []
  const captionsArray = []
  const videoPostersArray = []

  return (
    <Page title="Gallery" container={true} fluid={true}>
      <h2 className="page-title">Gallery</h2>

      <div className="masonry-with-columns">
        {galleryData
          ? galleryData.map((item, index) => {
            if (item.fileUrl.endsWith(".jpg")) {
              captionsArray.push(item.name)
              imagesArray.push(item.fileUrl)

                videoPostersArray.push(null)
                return (
                  <div key={index} className="image-thumb">
                    <div className="caption">{item.name}</div>
                    <img
                      src={item.fileUrl}
                      alt={item.name}
                      onClick={() => openLightboxOnSlide(index + 1)}
                    />
                  </div>
                )
              } else {
                return null;
               }
            })
          : null
        }
      </div>

      {galleryData ? (
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={imagesArray}
          slide={lightboxController.slide}
          captions={captionsArray}
          // UIFadeOutTime={}
          videosPosters={videoPostersArray}
        />
      ) : (
        <Loading />
      )}
    </Page>
  )
}

export default Gallery
