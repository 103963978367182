import React, { useState, useContext } from "react"
import { NavLink, useLocation } from "react-router-dom"
import StateContext from "../StateContext"

import { Squash as Hamburger } from "hamburger-react"

import { ReactComponent as AvailabilityIcon } from "../icons/availability-icon.svg"
import { ReactComponent as SpecificationIcon } from "../icons/specification-icon.svg"
import { ReactComponent as BookIcon } from "../icons/book-icon.svg"
import { ReactComponent as GalleryIcon } from "../icons/gallery-icon.svg"
import { ReactComponent as LocalAreaIcon } from "../icons/local-area-icon.svg"
import { ReactComponent as FavouritesIcon } from "../icons/home-heart-regular.svg"
import { ReactComponent as HomesIcon } from "../icons/home-icon.svg"
import { ReactComponent as SiteplanIcon } from "../icons/compass-icon.svg"
import { ReactComponent as VirtualTourIcon } from "../icons/virtual-tour-icon.svg"
import { ReactComponent as MasterPlanIcon } from "../icons/masterplan-icon.svg"
import { ReactComponent as DownloadsIcon } from "../icons/download-icon.svg"
import { ReactComponent as BookAppointmentIcon } from "../icons/book-appointment-icon.svg"



import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

function Menu() {
  const appState = useContext(StateContext)

  //menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  //check for route change and close menu
  const location = useLocation()
  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  //add body class to lock scroll when menu is open
  React.useEffect(() => {
    if (isMenuOpen === true) {
      disableBodyScroll(document.querySelector(".off-screen-menu-content"))
    } else {
      enableBodyScroll(document.querySelector(".off-screen-menu-content"))
    }
  }, [isMenuOpen])

  return (
    <>
      <div
        className={
          "off-screen-menu " + (isMenuOpen ? "off-screen-menu--is-visible" : "")
        }
      >
        <div className="off-screen-menu-content">
          <div className="main-menu">
            <div className="icon-wrap">
              <NavLink to="/types">
                <div className="icon-container">
                  <HomesIcon />
                </div>
                <h2>Homes</h2>
              </NavLink>
            </div>

            <div className="icon-wrap">
              <NavLink to="/plots">
                <div className="icon-container">
                  <AvailabilityIcon />
                </div>
                <h2>Availability</h2>
              </NavLink>
            </div>

            {appState.devMenuItems && appState.devMenuItems.brochure ? (
              <div className="icon-wrap">
                <NavLink to="/brochure">
                  <div className="icon-container">
                    <BookIcon />
                  </div>
                  <h2>Brochure</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.brochures ? (
              <div className="icon-wrap">
                <NavLink to="/brochure">
                  <div className="icon-container">
                    <BookIcon />
                  </div>
                  <h2>Literature</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.specification ? (
              <div className="icon-wrap">
                <NavLink to="/specification">
                  <div className="icon-container">
                    <SpecificationIcon />
                  </div>
                  <h2>Specification</h2>
                </NavLink>
              </div>
            ) : null}

            <div className="icon-wrap">
              <NavLink to="/local-area">
                <div className="icon-container">
                  <LocalAreaIcon />
                </div>
                <h2>Local Area</h2>
              </NavLink>
            </div>

            <div className="icon-wrap">
              <NavLink to="/favourites">
                <div className="icon-container">
                  <FavouritesIcon />
                </div>
                <h2>Favourites</h2>
              </NavLink>
            </div>

            <div className="icon-wrap">
              <NavLink to="/gallery">
                <div className="icon-container">
                  <GalleryIcon />
                </div>
                <h2>Gallery</h2>
              </NavLink>
            </div>

            <div className="icon-wrap">
              <NavLink to="/videos">
                <div className="icon-container">
                  <GalleryIcon />
                </div>
                <h2>Videos</h2>
              </NavLink>
            </div>

            {appState.devMenuItems && appState.devMenuItems.virtualTour ? (
              <div className="icon-wrap">
                <NavLink to="/exterior-tour">
                  <div className="icon-container">
                    <VirtualTourIcon />
                  </div>
                  <h2>Virtual Tour</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.typeTours ? (
              <div className="icon-wrap">
                <NavLink to="/interior-tour">
                  <div className="icon-container">
                    <VirtualTourIcon />
                  </div>
                  <h2>Interior Tour</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.siteplan ? (
              <div className="icon-wrap">
                <NavLink to="/siteplan">
                  <div className="icon-container">
                    <SiteplanIcon />
                  </div>
                  <h2>Plot Finder</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.masterPlan ? (
              <div className="icon-wrap">
                <NavLink to="/masterplan">
                  <div className="icon-container">
                    <MasterPlanIcon />
                  </div>
                  <h2>Master Plan</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.masterPlans ? (
              <div className="icon-wrap">
                <NavLink to="/masterplan">
                  <div className="icon-container">
                    <MasterPlanIcon />
                  </div>
                  <h2>Master Plans</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.downloads ? (
              <div className="icon-wrap">
                <NavLink to="/downloads">
                  <div className="icon-container">
                    <DownloadsIcon />
                  </div>
                  <h2>Downloads</h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.bookAppointment ? (
              <div className="icon-wrap">
                <NavLink to="/book-appointment">
                  <div className="icon-container">
                    <BookAppointmentIcon />
                  </div>
                  <h2>Book Appointment</h2>
                </NavLink>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="menu-toggle">
        <Hamburger
          rounded
          color="#ffffff"
          size={20}
          toggled={isMenuOpen}
          toggle={setIsMenuOpen}
        />
      </div>
    </>
  )
}

export default Menu
