import React, { useEffect, useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { useParams, Link } from "react-router-dom"
import Loading from "../components/Loading"

import Carousel from "react-bootstrap/Carousel"
import Table from "react-bootstrap/Table"

import { dynamicSort, stringToSlug } from "../components/Helpers"

import Badge from "react-bootstrap/Badge"
import PlotDetails from "../components/PlotDetails"
import CustomFields from "../components/CustomFields"
import FavButton from "../components/FavButton"

import { ReactComponent as SitePlanIcon } from "../icons/siteplan-icon.svg" 

import ReactHtmlParser from "react-html-parser"


function SinglePlot(props) {

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const {id} = useParams();
  const currentPlotID = props.compareID === undefined ?  parseInt(id) :  parseInt(props.compareID);

  const [currentPlotData, setCurrentPlotData] = useState()
  const [currentPlotStatus, setCurrentPlotStatus] = useState()
  const [currentBlockPlan, setCurrentBlockPlan] = useState()
  const [currentPlotView, setCurrentPlotView] = useState()

  useEffect(() => {
    appState.plotsData.filter((el) => {
      
      if (el.id === currentPlotID) {
        //order images
        let sortedImages = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, sortedImages }

        //order dims
        let sortedDims = el.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"))
        el = { ...el, sortedDims }

        setCurrentPlotData(el)

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === el.plotStatusId) {
            return el
          }
          return null
        })[0]

        setCurrentPlotStatus(status)

        //get the current plot view
        let view = null
        if (appState.plotVRs) {
          view = appState.plotVRs.find(
            ({ plot }) => stringToSlug(plot) === `plot-${el.plotNumber}`
          )
        }

        setCurrentPlotView(view)
      }
      return null
    })
  }, [
    appState.plotStatuses,
    appState.plotVRs,
    appState.plotsData,
    currentPlotID
  ])

  //get the current block plan
  useEffect(() => {
    let data = appState.plotContainers[0]

    let sites = ""
    if (data.plotContainerType.name !== "Site") {
      sites = appState.plotContainers[0].plotContainers
    } else {
      sites = appState.plotContainers
    }

    sites.forEach(function (site) {
      let blocks = site.plotContainers
      blocks.forEach(function (block) {
        let floors = block.plotContainers
        floors.forEach(function (floor) {
          if (floor.plotIds.includes(currentPlotID)) {
            let plots = floor.interactiveRegions
            plots.forEach(function (plot) {
              if (plot.targetId === currentPlotID) {
                setCurrentBlockPlan({
                  blockName: block.name,
                  floorName: floor.name,
                  imgURL: floor.interactiveAssetURI,
                  x: plot.startX,
                  y: plot.startY
                })
              }
            })
          }
        })
      })
    })
  }, [currentPlotID, appState.plotContainers])

  //set current light
  useEffect(() => {
    
    if(currentPlotData === undefined  )
     return;

     if(props.compareID === undefined){
      appDispatch({
        type: "setCurrentLight",
        data: { ID: currentPlotData.lightId, status: "on" }
      })
    }
  }, [currentPlotData])

  if (!currentPlotData)
    return (
      <div className="site-loader">
        <Loading />
      </div>
    )

  return (
    <Page
      title={`Plot ${currentPlotData.plotNumber}`}
      container={true}
      fluid={false}
      spaceBelow
    >
      <div className="row mt-5">
        <div className="col-md-6">
          {/* <div className="d-flex align-items-center mb-3">
            <h2 className="plot-name">Plot {currentPlotData.plotNumber}</h2>
            <div className="ml-auto d-block">
              <FavButton plotID={currentPlotData.id} />
            </div>
          </div> */}

          <h2 className="plot-name mb-3">
            <FavButton plotID={currentPlotData.id} /> Plot{" "}
            {currentPlotData.plotNumber}
          </h2>

          <h4 className="house-name">{currentPlotData.name}</h4>
          <div className="house-description">
            {ReactHtmlParser(currentPlotData.description)}
          </div>

          <PlotDetails item={currentPlotData} status={currentPlotStatus} />
          <CustomFields item={currentPlotData} status={currentPlotStatus} />
          {currentPlotView ? (
            <div className="plot-menu">
              <div className="item">
                <Link to={`/exterior-tour/${currentPlotView.arg}`}>
                  <SitePlanIcon /> View on Exterior Tour
                </Link>
              </div>
            </div>
          ) : null}
          {currentBlockPlan ? (
            <>
              <div className="block">
                <img
                  src={currentBlockPlan.imgURL}
                  alt={currentBlockPlan.floorName}
                />
                <div
                  className="block-marker"
                  style={{
                    left: `${currentBlockPlan.x * 100}%`,
                    top: `${currentBlockPlan.y * 100}%`,
                    backgroundColor: currentPlotStatus.color
                  }}
                ></div>
              </div>
              <p className="floor-name">{currentBlockPlan.floorName}</p>
            </>
          ) : null}
        </div>
        <div className="col-md-6">
          <div className="carousel-wrap">
            <div className="overlay-badge">
              <Badge
                pill
                variant="light"
                className="mb-2"
                style={{
                  color: currentPlotStatus.color,
                  border: `1px solid ${currentPlotStatus.color}`
                }}
              >
                {currentPlotStatus.name}
              </Badge>{" "}
            </div>
            <Carousel interval={null} controls={false}>
              {currentPlotData.sortedImages.map((img) => {
                return (
                  <Carousel.Item key={img.assetId}>
                    <img className="d-block w-100" src={img.fileUrl} alt="" />
                    {/* <Carousel.Caption>
                      <p>{img.name}</p>
                    </Carousel.Caption> */}
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>

          <p className="small mt-5">
            {currentPlotData.plotType?.disclaimerText}
          </p>

          <Table size="sm" responsive className="mt-4">
            <tbody>
              {currentPlotData.sortedDims.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.imperialDimensions}</td>
                    <td>{item.metricDimensions}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      { props.compareID === undefined &&
      <div className="sub-menu centered">
        <div className="container compare-button">
          <div className="btn round uppercase btn-secondary box-shadow">
            <Link to={`/compare/${currentPlotData.id}`} >
              compare
            </Link> 
          </div>
        </div>
      </div>
      }
    </Page>
  )
}

export default SinglePlot
