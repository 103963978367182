import React, { useContext, useRef, useState } from "react"
import DispatchContext from "../DispatchContext"
import StateContext from "../StateContext"

import { useHistory } from "react-router-dom"
import Popover from "./Popover"

function Iframe(props) {
  const { src } = props

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const iframeRef = useRef()
  let [iframeLoaded, setIframeLoaded] = useState(0)

  React.useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {
          source: "thinkOnline",
          payload: {
            event: "filterData",
            filters: appState.filters,
            filteredHomes: appState.filteredHomes
          }
        },
        "*"
      )
    }
  }, [appState.filters, iframeLoaded, appState.filteredHomes])

  const handelIframeLoaded = function () {
    setIframeLoaded(iframeLoaded++)
  }

  React.useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.data.source === "virtual-tour" &&
        event.data.payload.event === "route"
      ) {
        goToRoute(event.data.payload.location)
        console.log("Event", event.data)
      } else if (
        event.data.source === "virtual-tour" &&
        event.data.payload.event === "popover"
      ) {
        appDispatch({ type: "showPopover", data: event.data.payload.location })
        console.log("Event", event.data)
      } else {
        return null
      }
    }

    window.addEventListener("message", handleMessage)

    //cleanup
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  })

  const history = useHistory()
  const goToRoute = (location) => history.push(location)
  return (
    <>
      <iframe
        className="full-iframe"
        src={src}
        width="100%"
        height="100%"
        title="Siteplan"
        ref={iframeRef}
        onLoad={setIframeLoaded}
      ></iframe>
      <Popover />
    </>
  )
}

export default Iframe
