import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import PlotDetails from "./PlotDetails"
import StateContext from "../StateContext"
import FavButton from "../components/FavButton"

function PlotCard(props) {
  const appState = useContext(StateContext)
  const { item } = props

  //get status
  let status = appState.plotStatuses.filter((el) => {
    if (el.id === item.plotStatusId) {
      return el
    }
    return null
  })[0]

  return (
    <div className="col-md-4 col-lg-3 plot-card item" key={item.id}>
      <Card className="mb-4">
        <Card.Header>
          <h3 className="plot-name">
            <FavButton plotID={item.id} /> Plot{" "}
            <span className="nowrap">{item.plotNumber}</span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div className="d-sm-flex d-md-block d-lg-block d-xl-flex">
            <h4 className="house-name">{item.name}</h4>
            <div className="ml-auto">
              <Badge
                pill
                variant="light"
                style={{
                  color: status.color,
                  border: `1px solid ${status.color}`
                }}
              >
                {status.name}
              </Badge>{" "}
            </div>
          </div>
          <PlotDetails item={item} status={status} />
          <Link to={`/plots/${item.id}`} className="btn btn-primary">
            View Home
          </Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export default PlotCard
