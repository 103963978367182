import React, { useState, useContext, useEffect } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import { ReactComponent as HeartSolid } from "../icons/heart-solid.svg"
import { ReactComponent as HeartLight } from "../icons/heart-light.svg"

function FavButton(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const { plotID } = props

  const [isFav, setIsFav] = useState(false)

  function handleClick(plotID) {
    if (isFav) {
      appDispatch({ type: "removeFavPlot", data: plotID })
    } else {
      appDispatch({ type: "addFavPlot", data: plotID })
    }
  }

  //update fav status
  useEffect(() => {
    if (appState.favPlots && plotID && appState.favPlots.includes(plotID)) {
      setIsFav(true)
    } else {
      setIsFav(false)
    }
  }, [appState.favPlots, plotID])

  return (
    <span
      className="fav-button svg-icon-inline baseline tight"
      onClick={() => handleClick(plotID)}
    >
      {isFav ? <HeartSolid /> : <HeartLight />}
    </span>
  )
}

export default FavButton
