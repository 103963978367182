import React, { useContext, useEffect, useState } from "react"
import Badge from "react-bootstrap/Badge"
import StateContext from "../StateContext"
import { priceFormatter } from "./Helpers"


function PlotCard(props) {
  const appState = useContext(StateContext)
  const { item } = props

  const [currentPlotStatus, setCurrentPlotStatus] = useState()
  
  useEffect(()=>{
    if(item === undefined)
    return;

    let status = appState.plotStatuses.filter((el) => {
        if (el.id === item.plotStatusId) {
            return el
        }
        return null
    })[0]
    
    setCurrentPlotStatus(status)
  },[item])


  return (
    <div className="compare-list-item box-shadow" key={item.id} onClick={()=> props.myFunction(item.id)}>
        <div className="compare-list-item__info">
            <div className="plot-number">{item.plotNumber}</div>
            <div className="price">{priceFormatter(item.price)}</div>
            <div className="compare-list-item__buttons">
                {(currentPlotStatus !== undefined && 
                <div className="overlay-badge">
                <Badge
                    pill
                    variant="light"
                    className="mb-2"
                    style={{
                        color: `${currentPlotStatus.color}`,
                        border: `1px solid ${currentPlotStatus.color}`
                    }}
                    >
                    {currentPlotStatus.name}
                    </Badge>{" "}
                </div>
                )}
                <div className="compare-list-item__buttons__compare-button">+</div>
            </div>
        </div>
    </div>
  )
}

export default PlotCard
