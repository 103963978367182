import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import NotFound from "./NotFound";
import Page from "../components/Page";
import Form from "react-bootstrap/Form";

function SetMarketingSuite() {
  let { key } = useParams();
  const [cookies, setCookie] = useCookies([
    "inMarketingSuite",
    "haveLights",
    "lightServerAddress",
  ]);

  //state
  const [inMarketingSuite, setInMarketingSuite] = useState(
    cookies.inMarketingSuite === "true" ? true : false
  );
  const [haveLights, setHaveLights] = useState(
    cookies.haveLights === "true" ? true : false
  );
  const [lightServerAddress, setLightServerAddress] = useState(
    cookies.lightServerAddress
  );

  //set cookies from state
  useEffect(() => {
    if (inMarketingSuite) {
      setCookie("inMarketingSuite", "true", { path: "/", maxAge: 31536000 });
    } else {
      setCookie("inMarketingSuite", "false", { path: "/", maxAge: 31536000 });
      setHaveLights(false);
    }
  }, [inMarketingSuite]);

  useEffect(() => {
    if (haveLights) {
      setCookie("haveLights", "true", { path: "/", maxAge: 31536000 });
    } else {
      setCookie("haveLights", "false", { path: "/", maxAge: 31536000 });
      setLightServerAddress("");
    }
  }, [haveLights]);

  useEffect(() => {
    if (lightServerAddress) {
      setCookie("lightServerAddress", lightServerAddress, {
        path: "/",
        maxAge: 31536000,
      });
    } else {
      setCookie("lightServerAddress", "", {
        path: "/",
        maxAge: 31536000,
      });
    }
  }, [lightServerAddress]);

  function toggleInMarketingSuite() {
    setInMarketingSuite(!inMarketingSuite);
  }

  function toggleHaveLights() {
    setHaveLights(!haveLights);
  }

  function handleIPChange(event) {
    setLightServerAddress(event.target.value);
  }

  if (key === window["runConfig"].devKEY) {
    return (
      <Page title="Set Marketing Suite" fluid={false} container={true}>
        <h2 className="page-title">App Local Settings</h2>

        {key === window["runConfig"].devKEY ? (
          <Form>
            <Form.Check
              type="switch"
              id="in-marketing-suite"
              label="Set location as marketing suite"
              checked={inMarketingSuite}
              onChange={toggleInMarketingSuite}
            />
            <Form.Check
              type="switch"
              label="Light server"
              id="disabled-custom-switch"
              checked={haveLights}
              onChange={toggleHaveLights}
              disabled={!inMarketingSuite}
            />

            {haveLights ? (
              <Form.Group controlId="formLightServerIP" className="mt-3">
                <Form.Label>Light Server IP Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IP"
                  value={lightServerAddress}
                  onChange={handleIPChange}
                />
                <Form.Text className="text-muted">
                  Please enter the IP address and port e.g.
                  wss://192.168.1.88:8080
                </Form.Text>
              </Form.Group>
            ) : null}
          </Form>
        ) : null}
      </Page>
    );
  } else {
    return <NotFound />;
  }
}

export default SetMarketingSuite;
