import React, { useState, useContext } from "react"
import PageWrap from "../components/Page"
import { Document, Page, pdfjs } from "react-pdf"
import Loading from "../components/Loading"
import StateContext from "../StateContext"
import { SizeMe } from "react-sizeme"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function Brochure() {
  const appState = useContext(StateContext)

  const url = appState.devMenuItems.specification

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function prev() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  function next() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <PageWrap title="Brochure" fluid={false} container={false}>
      {url ? (
        <SizeMe
          monitorHeight={true}
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div className="resize-container">
              <div style={{ maxWidth: size.width, maxHeight: size.height }}>
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={Loading}
                  renderMode={"canvas"}
                >
                  <Page
                    pageNumber={pageNumber}
                    height={size.width > size.height ? size.height - 60 : null}
                    width={size.width > size.height ? null : size.width - 50}
                    renderTextLayer={false}
                  />
                </Document>
              </div>
            </div>
          )}
        />
      ) : null}

      {numPages > 1 ? (
        <div className="pdf-controls">
          <button
            onClick={prev}
            className="btn btn-secondary"
            disabled={pageNumber === 1 ? true : false}
          >
            Prev
          </button>
          <span className="page-status">
            {pageNumber} of {numPages}
          </span>
          <button
            onClick={next}
            className="btn btn-secondary"
            disabled={pageNumber === numPages ? true : false}
          >
            Next
          </button>
        </div>
      ) : null}
    </PageWrap>
  )
}

export default Brochure
