import React, { useContext, useCallback, useState } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"

import { ReactComponent as TouchIcon } from "../icons/touch-icon.svg"

import HomePagePopover from "../components/HomePagePopover"

function Home() {
  const appState = useContext(StateContext)

  let BackgroundImageURL = appState.devStyle.images.backgroundImageUrl

  let backgroundImageStyle = !appState.devStyle.images.wallIdleVideoUrl
    ? { backgroundImage: `url(${BackgroundImageURL})` }
    : null

  //home popover state
  const [isHomePopoverOpen, setIsHomePopoverOpen] = useState(false)

  const toggleHomePopoverOpen = useCallback(() => {
    setIsHomePopoverOpen(!isHomePopoverOpen)
  }, [isHomePopoverOpen])

  const closeHomePopoverOpen = useCallback(() => {
    setIsHomePopoverOpen(false)
  }, [])

  const openHomePopoverOpen = useCallback(() => {
    setIsHomePopoverOpen(true)
  }, [])

  return (
    <Page title="Home" container={false}>
      {appState.devStyle.images.wallIdleVideoUrl ? (
        <video
          className="header-video"
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src={appState.devStyle.images.wallIdleVideoUrl}
            type="video/mp4"
          />
        </video>
      ) : null}

      <div className="home-content-wrap" style={backgroundImageStyle}>
        <div className="home-content text-center">
          <p>
            <img
              src="./redrow-logo-2.png"
              alt="Redrow Logo"
              className="img-fluid redrow-logo"
              width="200"
            />
          </p>

          {appState.devStyle && appState.devStyle.images.tabletLogoUrl ? (
            <p>
              <img
                src={appState.devStyle.images.tabletLogoUrl}
                alt={appState.devName}
                className="img-fluid"
                width="200"
              />
            </p>
          ) : null}
          <h2 className="page-title welcome">Welcome to</h2>
          <h2 className="page-title site-name">{appState.devName}</h2>

          <div onClick={toggleHomePopoverOpen} className="dot pulse mt-2">
            <div>
              <TouchIcon />
            </div>
          </div>
        </div>
      </div>

      <HomePagePopover
        isOpen={isHomePopoverOpen}
        setIsOpen={setIsHomePopoverOpen}
      />
    </Page>
  )
}

export default Home
