import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import PlotCard from "../components/PlotCard"
import RefineMenu from "../components/RefineMenu"
import FilterStatusBar from "../components/FilterStatusBar"
import FavStatusBar from "../components/FavStatusBar"
import { useMediaQuery } from "react-responsive"
import { sortBy } from "../components/Helpers"

function Plots() {
  //app state
  const appState = useContext(StateContext)

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      // setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )
  
  const sortedData = [...appState.filteredHomes].sort((a, b) => sortBy(a, b, 'plotNumber'));

  return (
    <Page title="Our Homes" container={true} fluid={false} spaceBelow>
      <h2 className="page-title">
        Find<span className="bold"> Your New Home</span>
      </h2>

      <div className="top-bar row">
        <div className="col-md-12">
          <FilterStatusBar />
        </div>
      </div>

      <div className="plots-list">
        <div className="plots-grid row" key="plots-grid">
          {sortedData.length >= 1 ? (
            sortedData.map((item) => {
              return <PlotCard item={item} key={item.id} />
            })
          ) : (
            <div className="col-md-12 plot-card item" key={0}>
              <Card className="mb-4">
                <Card.Body>No available plots.</Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>

      <div className="sub-menu centered">
        <div className="container">
          <RefineMenu isDesktopOrLaptop={isDesktopOrLaptop} />
          <FavStatusBar isDesktopOrLaptop={isDesktopOrLaptop} />
        </div>
      </div>

      {appState.filters && appState.filters.some((e) => e.active) ? null : (
        <div className="view-all-homes">
          <Link className="view-all-homes-link" to="all-plots">
            View all homes
          </Link>
        </div>
      )}
    </Page>
  )
}

export default Plots
