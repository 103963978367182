import React, { useState, useEffect, useContext } from "react"
import Page from "../components/Page"
import Axios from "axios"
import StateContext from "../StateContext"
import Loading from "../components/Loading"
import FsLightbox from "fslightbox-react"
import VideoThumbnail from "../components/VideoThumbnail"

function Videos() {
    //will get the data for the gallery named 'Videos' only from the backend
  const appState = useContext(StateContext)

  const [galleryData, setGalleryData] = useState()
  const [videoData, setVideoData] = useState();
  

  useEffect(()=>{
    if(galleryData === undefined)return;
    const temp = galleryData.filter((node) => node.name === 'Videos');
    if(temp === undefined)return;
    setVideoData(temp[0].assets);

    let root = document.documentElement;
    root.style.setProperty("--navVisibility", "hidden");

},[galleryData])

  //get the location data from api
  useEffect(() => {
    if (!localStorage.getItem("videoData")) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `GetGalleries/${appState.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": appState.key }
            }
          )
          if (response.data) {
            //set state
            setGalleryData(response.data)

            //set localStorage
            let json = JSON.stringify(response.data)
            localStorage.setItem("videoData", json)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      //we have local data

      //set state
      const data = JSON.parse(localStorage.getItem("videoData"))
      setGalleryData(data);
    }
  }, [appState.devID, appState.key])

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  const imagesArray = []
  const captionsArray = []
  const videoPostersArray = [];
  
  return (
    <Page title="Videos" container={true} fluid={true}>
      <h2 className="page-title">Videos</h2>
      <VideoThumbnail videoData={videoData}
        myfunction={openLightboxOnSlide}
        imagesArray={imagesArray}
        captionsArray={captionsArray}
        videoPostersArray={videoPostersArray}
     />
     {videoData ? (
     <FsLightbox
     customToolbarButtons={undefined}
     disableThumbs={true}
     loadOnlyCurrentSource={true}
     disableSlideSwiping={true}
        toggler={lightboxController.toggler}
        sources={imagesArray}
        slide={lightboxController.slide}
        captions={captionsArray}
        videosPosters={videoPostersArray}
     />
     ) : (
     <Loading />
     )}
    </Page>
  )
}

export default Videos
