import React, { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"

import StateContext from "../StateContext"
import { SizeMe } from "react-sizeme"
import ItemMenu from "../components/ItemMenu"


function MasterPlan() {
  const appState = useContext(StateContext)

  let devName = appState.devName
  useEffect(() => {
    document.title = `Master Plan | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  const url = appState.devMenuItems.masterPlan
  const items = appState.devMenuItems.masterPlans

  const id = useParams().id
  const currentItem = id ? items.find((el) => el.slug === id) : null

  //check for single or multiple items
  let currentURL = ''
  if(url) {
    currentURL = url
  } else if (items) {
    currentURL = id ? currentItem.url : items[0].url
  }


  return (
    <>
    {url || (currentItem && currentItem.url) || (items && items[0].url) ? (
    
      <SizeMe
        monitorHeight={true}
        refreshRate={128}
        refreshMode={"debounce"}
        render={({ size }) => (
          <div className="resize-container">
            <img
              className="resize-image"
              style={{ maxWidth: size.width, maxHeight: size.height }}
              src={currentURL}
              alt=""
            />
          </div>
        )}
      />

      ) : null}

      <div className="sub-menu centered">
        <div className="container">
        {items ? (
          <ItemMenu
            items={items}
            currentItem={id ? currentItem.slug : items[0].slug}
            route="masterplan"
            label="Master Plans"
          />
          ) : null}
        </div>
        
      </div>
    </>
  )
}

export default MasterPlan
