import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { ReactComponent as BackArrow } from "../icons/long-arrow-left-light.svg"

function Menu() {
  const history = useHistory()
  const location = useLocation()

  function goBack() {
    history.goBack()
  }

  return (
    <>
      {location.pathname !== "/" ? (
        <div className="back-button" onClick={goBack}>
          <BackArrow />
        </div>
      ) : null}
    </>
  )
}

export default Menu
