import React, { useState, useEffect, useCallback, useRef } from "react"

import { Link } from "react-router-dom"

function ItemMenu(props) {
  const { items, currentItem, route, label } = props

  //item menu state
  const [isItemMenuOpen, setIsItemMenuOpen] = useState(false)

  const toggleItemMenu = useCallback(() => {
    setIsItemMenuOpen(!isItemMenuOpen)
  }, [isItemMenuOpen])

  const closeItemMenu = useCallback(() => {
    setIsItemMenuOpen(false)
  }, [])

  const openItemMenu = useCallback(() => {
    setIsItemMenuOpen(true)
  }, [])

  /**
   * Hook that alerts clicks outside of the passed ref
   */

  //!event.target.classList.contains("item-menu-button")

  function useOutsideAlerter(wrapperRef, buttonRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        //if not inside the container
        if (
          wrapperRef.current &&
          buttonRef.current &&
          !wrapperRef.current.contains(event.target) &&
          !buttonRef.current.contains(event.target)
        ) {
          //if the menu is open and the click even is not on the button
          if (isItemMenuOpen) {
            closeItemMenu()
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [wrapperRef, buttonRef, isItemMenuOpen])
  }

  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)
  useOutsideAlerter(wrapperRef, buttonRef)

  return (
    <>
      {items && items.length ? (
        <div className="item-menu">
          <div
            className={`btn uppercase btn-secondary box-shadow item-menu-button ${
              isItemMenuOpen ? "active" : ""
            }`}
            onClick={toggleItemMenu}
            ref={buttonRef}
          >
            <div className="button-text-link">
              <span className="button-text ">{label}</span>
            </div>
          </div>

          <div
            className={
              "off-screen-item-menu " +
              (isItemMenuOpen ? "off-screen-item-menu--is-visible" : "")
            }
            ref={wrapperRef}
          >
            {items
              ? items.map((item, key) => {
                  return (
                    <Link
                      key={key}
                      to={`/${route}/${item.slug}`}
                      className={
                        currentItem === item.slug
                          ? "item-menu-item active"
                          : "item-menu-item"
                      }
                    >
                      <h5 className="uppercase">{item.title}</h5>
                    </Link>
                  )
                })
              : null}
          </div>
        </div>
      ) : null}{" "}
    </>
  )
}

export default ItemMenu
